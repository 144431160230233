import { PreRelease, User } from "@/shared/models";
import classNames from "classnames";
import Image from "next/image";
import React, { FC, useMemo, useState } from "react";
import Link from "next/link";
import useCountdown from "@/client/hook/useCountdown";
import { getArtistUrl, getAvantsUrl } from "@/shared/utils/createUrls";
import { DEFAULT_IMAGE_PLACEHOLDER } from "@/shared/constants/path";
import { getAvatar, getCDNUrl } from "@/shared/utils/images";
import { BsThreeDots } from "react-icons/bs";
import Router, { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import PreReleaseCardMenu from "./PreReleaseCardMenu";
import { formatShortDateText } from "@/shared/utils/date";
import GLBRenderer from "./GLBRenderer";
import Moment from "react-moment";
import { getItemTypeIcon } from "../utils/icons";
import Verified from "./icons/Verified";

interface Props {
  preRelease: PreRelease;
  itemId?: number;
  displayCountDown?: boolean;
  artistPage?: boolean;
  userPage?: boolean;
  className?: string;
  artistPreview?: boolean;
  showFanCard?: boolean;
  user?: User;
  isOwner?: boolean;
}

const PreReleaseCard: FC<Props> = ({
  preRelease,
  className,
  displayCountDown,
  artistPage = false,
  userPage = false,
  artistPreview = false,
  showFanCard = false,
  user,
  isOwner = false,
}) => {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    isLoadedCountdown,
    startCountdown,
    endCountdown,
    isStartCountDownActive,
    isEndCountDownActive,
  } = useCountdown(preRelease.startDate, preRelease.endDate, true);

  const canListen = new Date(preRelease.listenDate) < new Date();

  const isTransferPage: boolean = useMemo(() => {
    return router.pathname.includes("transfer-preRelease");
  }, [router.pathname]);

  const getFormattedDate = () => {
    if (preRelease.fanCardCopy) {
      return (
        <>
          <Moment
            className="font-semibold"
            format="dddd"
            date={new Date(preRelease.fanCardCopy.sellDate)}
          />
          <Moment
            format="DD MMM"
            date={new Date(preRelease.fanCardCopy.sellDate)}
          />
        </>
      );
    }
  };

  const _class = classNames(
    `flex flex-col space-y-5 ${
      artistPage ? "h-[400px]" : "h-[450px]"
    } w-[292px] border-2 border-[#282829] bg-gray-card px-6 py-4 rounded-sm relative`,
    className
  );

  const goToAvantsPage = () => {
    Router.push(getAvantsUrl(preRelease));
  };

  return (
    <section className={_class}>
      <div className="flex flex-row justify-between items-center">
        {/* User page always shows format date.
            First preRelease shows timer (countDown).
            Shows now on sale if there's no timer or sold out if remainingNft is zero
        */}
        <div className="flex flex-row items-center text-sm font-poppins leading-tight text-white break-words">
          <div className="flex items-center space-x-2 mt-2  w-full">
            {userPage && preRelease.fanCardCopy ? (
              getFormattedDate()
            ) : (
              <>
                {isLoadedCountdown && (
                  <>
                    {isStartCountDownActive && isEndCountDownActive && (
                      <div className="flex space-x-2 items-center">
                        <Image
                          src="/static/icons/not_started.svg"
                          alt="lock icon"
                          width={16}
                          height={21}
                        />
                        <p className="flex space-x-2 text-xs">Non Started</p>
                      </div>
                    )}
                    {!isStartCountDownActive &&
                      isEndCountDownActive &&
                      canListen && (
                        <div className="flex space-x-2 items-center">
                          <Image
                            src="/static/icons/volume_up.svg"
                            alt="ticket icon"
                            width={22}
                            height={28}
                          />
                          <div className="flex space-x-2 items-center text-[14px]">
                            <p className="text-[16px]">Broadcasting</p>
                          </div>
                        </div>
                      )}
                    {!isStartCountDownActive &&
                      isEndCountDownActive &&
                      !canListen && (
                        <div className="flex space-x-2 items-center">
                          <Image
                            src="/static/icons/ticket.svg"
                            alt="ticket icon"
                            width={22}
                            height={28}
                          />
                          <div className="flex space-x-2 items-center text-[14px]">
                            <p className="text-[16px]">Tickets</p>
                          </div>
                        </div>
                      )}
                    {!isStartCountDownActive && !isEndCountDownActive && (
                      <div className="flex space-x-2 items-center">
                        <Image
                          src="/static/icons/play.svg"
                          alt="ticket icon"
                          width={22}
                          height={28}
                        />
                        <div className="flex space-x-2 items-center text-[14px]">
                          <p className="text-[16px]">Finished</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {showFanCard && userPage && preRelease.fanCardCopy ? (
          <span className="bg-[#2f2f31] max-h-[32px] rounded-[4px] py-1 px-2 text-xs font-inter leading-normal font-medium text-xclusivs">
            FanCard
          </span>
        ) : (
          <>
            {!artistPreview && isLoadedCountdown && (
              <>
                <Image
                  src={getItemTypeIcon("avants", "large") ?? ""}
                  width={100}
                  height={32}
                  alt={`${preRelease.id}-icon-item`}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className=" cursor-pointer w-[244px] h-[244px]  relative flex flex-col  items-center justify-center">
        {showFanCard &&
        userPage &&
        preRelease.fanCardCopy &&
        preRelease.glbModel ? (
          <GLBRenderer model={preRelease.glbModel} scale={0.08} />
        ) : (
          <button onClick={goToAvantsPage}>
            <Image
              src={getCDNUrl(preRelease.cover)}
              alt={`${preRelease.name} image`}
              layout="fill"
              blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
              placeholder="blur"
              className="rounded-md object-cover"
            />
            {!artistPreview && (
              <Image
                src="/static/icons/play.svg"
                alt="play icon"
                width={40}
                height={40}
              />
            )}
          </button>
        )}
      </div>

      <div className="flex flex-col space-y-3">
        <div className="flex flex-col space-y-4">
          <Link passHref href={getAvantsUrl(preRelease)}>
            <a>
              <h3
                title={preRelease.name}
                className="text-lg font-inter leading-normal text-white whitespace-nowrap truncate"
              >
                {preRelease.name}
              </h3>
            </a>
          </Link>

          {!artistPage && (
            <Link passHref href={getArtistUrl(preRelease.artist.username)}>
              <a className="flex flex-row items-center justify-between">
                <div className="flex flex-row space-x-2 items-center">
                  <div className="w-6 h-6 relative">
                    <Image
                      src={getAvatar(preRelease.artist.avatar)}
                      alt={`${preRelease.artist.username} image`}
                      layout="fill"
                      objectFit="cover"
                      className="rounded-full object-cover"
                      blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                      placeholder="blur"
                    />
                  </div>

                  <div className="flex flex-col w-max mr-2">
                    <h4
                      title={preRelease.artist.name}
                      className="text-15 font-poppins leading-tight font-semibold whitespace-nowrap truncate uppercase text-white"
                    >
                      {preRelease.artist.name}
                    </h4>
                  </div>

                  <div>
                    <Verified size={14} />
                  </div>
                </div>
              </a>
            </Link>
          )}
        </div>

        <div className="flex justify-between">
          <div className="flex justify-end w-full">
            {!isTransferPage && userPage && (
              <BsThreeDots
                className="cursor-pointer"
                size={24}
                onClick={(event) => {
                  event.preventDefault();
                  setMenuOpen((state) => !state);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {menuOpen && (
        <>
          <PreReleaseCardMenu
            user={user}
            isOwner={isOwner}
            preRelease={preRelease}
            isOpen={menuOpen}
            setIsOpen={(value) => setMenuOpen(value)}
          />
        </>
      )}
    </section>
  );
};

export default PreReleaseCard;
