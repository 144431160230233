import { FC, useEffect } from "react";
import Image from "next/image";
import { PreRelease, User } from "@/shared/models";
import useNftOpenSeaUrl from "../hook/useNftOpenSeaUrl";
import Link from "next/link";
import classNames from "classnames";
import useUserAgent from "../hook/useUserAgent";
import useFileSaver from "../hook/useFileSaver";
import Router from "next/router";

interface Props {
  preRelease: PreRelease;
  isOpen: boolean;
  user?: User;
  setIsOpen: (value: boolean) => void;
  isOwner?: boolean;
}

const PreReleaseCardMenu: FC<Props> = ({
  preRelease,
  isOpen,
  user,
  setIsOpen,
  isOwner = false,
}) => {
  const { isMobile } = useUserAgent();

  const { nftOpenseaUrl } = useNftOpenSeaUrl(null, preRelease);

  const { downloadFile } = useFileSaver();

  useEffect(() => {
    const closeModal = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (isOpen && !target.closest("#menu")) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("mousedown", closeModal);
    return () => document.body.removeEventListener("mousedown", closeModal);
  }, [isOpen, setIsOpen]);

  const handleDownloadCertificate = async () => {
    if (preRelease.certificate && user && isOwner) {
      await downloadFile(
        preRelease.certificate?.certificateUrl,
        `Avants Certificate - ${preRelease.name} - ${user.username}.png`,
        {
          type: "image/png",
        }
      );
    }
  };

  const handleViewFanCard = async () => {
    //Redirect to page to show 3d card
    Router.push(`/avants/3dCard?slug=${preRelease.slug}`);
  };

  const _class = classNames(
    `absolute flex flex-col mx-6 px-6 gap-5 py-4 rounded-md bg-gray-450 right-0 z-10 ${
      preRelease.fanCardCopy ? "bottom-[-80px] " : "bottom-[-120px]"
    } `,
    !isOwner && "bottom-[-30px]",
    isMobile && !preRelease.fanCardCopy && "bottom-[10px]",
    isMobile && preRelease.fanCardCopy && "bottom-[80px]"
  );

  return (
    <>
      <ul id="menu" className={_class}>
        <li>
          <Link passHref href={nftOpenseaUrl}>
            <a target="_blank">
              <span className="flex gap-4 cursor-pointer">
                <Image
                  src="/static/icons/external_link.png"
                  alt="link icon"
                  height={20}
                  width={20}
                  objectFit="cover"
                  className="object-cover"
                />

                <span>View on Opensea</span>
              </span>
            </a>
          </Link>
        </li>
        {preRelease.certificate && isOwner && (
          <li>
            <Link passHref href={preRelease.certificate.certificateUrl}>
              <a target="_blank">
                <span
                  title="View Certificate"
                  className="flex gap-4 cursor-pointer"
                >
                  <Image
                    src="/static/icons/full_screen.svg"
                    alt="view icon"
                    height={20}
                    width={20}
                    objectFit="cover"
                    className="object-cover"
                  />

                  <span>View Certificate</span>
                </span>
              </a>
            </Link>
          </li>
        )}
        {preRelease.certificate && isOwner && (
          <li onClick={handleDownloadCertificate}>
            <span
              title="Download Certificate"
              className="flex gap-4 cursor-pointer"
            >
              <Image
                src="/static/icons/download.svg"
                alt="download icon"
                height={20}
                width={20}
                objectFit="cover"
                className="object-cover"
              />

              <span>Download Certificate</span>
            </span>
          </li>
        )}
        {preRelease.fanCardCopy && isOwner && (
          <li>
            <span
              title="View 3D Card"
              onClick={handleViewFanCard}
              className="flex gap-4 cursor-pointer"
            >
              <Image
                src="/static/icons/full_screen.svg"
                alt="view icon"
                height={20}
                width={20}
                objectFit="cover"
                className="object-cover"
              />

              <span>View 3D Card</span>
            </span>
          </li>
        )}
      </ul>
    </>
  );
};

export default PreReleaseCardMenu;
