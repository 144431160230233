import React, { FC, Suspense } from "react";
import Head from "next/head";
import { Canvas, useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

interface Props {
  model: string;
  scale?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
}
const Model: FC<Props> = ({ model, scale = 0.07 }) => {
  // location of the 3D model
  const gltf = useLoader(GLTFLoader, model);
  return (
    <>
      {/* Use scale to control the size of the 3D model */}
      <primitive object={gltf.scene} scale={scale} />
    </>
  );
};

const GLBRenderer: FC<Props> = ({ model, scale, style }) => {
  return (
    <Canvas
      shadows
      dpr={[1, 2]}
      camera={{ position: [0, 0, 4], fov: 50 }}
      style={style}
    >
      <ambientLight intensity={0.7} />
      <spotLight
        intensity={1}
        angle={0.1}
        penumbra={1}
        position={[10, 15, 10]}
        castShadow
      />
      <Suspense fallback={null}>
        <Model model={model} scale={scale} />
        <Environment preset="city" />
      </Suspense>
      <OrbitControls autoRotate />
    </Canvas>
  );
};

export default GLBRenderer;
