import { saveAs } from "file-saver";

const useFileSaver = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadFile = async (url: string, filename: string, metadata: any) => {
    const response = await fetch(url);
    const fileData = await response.blob();
    const file = new File([fileData], filename, metadata);
    saveAs(file, filename);
  };

  const downloadJson = async (data: any, filename: string) => {
    const file = new File([JSON.stringify(data)], filename, {
      type: "application/json;charset=utf-8",
    });
    saveAs(file, filename);
  };
  return {
    downloadFile,
    downloadJson,
  };
};

export default useFileSaver;
